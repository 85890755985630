import { CodeSurfer, CodeSurferColumns, Step } from "code-surfer";
import "../../../../../../styles.css";
import PWA from "../../../../../../images/PWA.png";
import zenduty from "../../../../../../images/zen.svg";
import PWAzen from "../../../../../../images/PWA-zen.svg";
import pwacompare from "../../../../../../images/pwa-web-native.png";
import https from "../../../../../../images/https.jpeg";
import sw from "../../../../../../images/service-worker.jpeg";
import core from "../../../../../../images/core.png";
import swEvents from "../../../../../../images/sw-events.png";
import devtools from "../../../../../../images/devtools.png";
import devtoolsSW from "../../../../../../images/devtools-sw.png";
import installD from "../../../../../../images/installD.png";
import installM from "../../../../../../images/installM.png";
import installM1 from "../../../../../../images/installM1.png";
import installM2 from "../../../../../../images/installM2.png";
import appd from "../../../../../../images/appd.png";
import appm from "../../../../../../images/appm.png";
import appm1 from "../../../../../../images/appm1.png";
import twitter from "../../../../../../images/twitter.svg";
import linkedin from "../../../../../../images/linkedin.svg";
import hey from "../../../../../../images/hey.gif";
import thank from "../../../../../../images/thank.gif";
import { github, nightOwl } from "@code-surfer/themes";
import React from 'react';
export default {
  CodeSurfer: CodeSurfer,
  CodeSurferColumns: CodeSurferColumns,
  Step: Step,
  PWA: PWA,
  zenduty: zenduty,
  PWAzen: PWAzen,
  pwacompare: pwacompare,
  https: https,
  sw: sw,
  core: core,
  swEvents: swEvents,
  devtools: devtools,
  devtoolsSW: devtoolsSW,
  installD: installD,
  installM: installM,
  installM1: installM1,
  installM2: installM2,
  appd: appd,
  appm: appm,
  appm1: appm1,
  twitter: twitter,
  linkedin: linkedin,
  hey: hey,
  thank: thank,
  github: github,
  nightOwl: nightOwl,
  React: React
};