import "core-js/modules/es6.array.for-each";
import _slicedToArray from "/opt/buildhome/repo/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import "core-js/modules/es6.object.assign";
import { useState, useEffect } from "react";
import { unstable_batchedUpdates } from "react-dom";
var round = Math.round,
    sqrt = Math.sqrt,
    exp = Math.exp,
    sin = Math.sin,
    cos = Math.cos,
    pow = Math.pow;
export function useSpring(target, config) {
  var _Object$assign = Object.assign({
    stiffness: 170,
    damping: 26,
    mass: 1,
    decimals: 2
  }, config),
      k = _Object$assign.stiffness,
      c = _Object$assign.damping,
      m = _Object$assign.mass,
      teleport = _Object$assign.teleport,
      decimals = _Object$assign.decimals;

  var _useState = useState({
    spring: function spring(t) {
      return [target, 0];
    },
    now: null
  }),
      _useState2 = _slicedToArray(_useState, 2),
      state = _useState2[0],
      setState = _useState2[1];

  var _ref = teleport ? [target, 0] : state.spring(state.now),
      _ref2 = _slicedToArray(_ref, 2),
      x = _ref2[0],
      v = _ref2[1];

  useEffect(function () {
    var now = performance.now();

    var _state$spring = state.spring(now),
        _state$spring2 = _slicedToArray(_state$spring, 2),
        x0 = _state$spring2[0],
        v0 = _state$spring2[1];

    var spring = newSpring(target, x0, v0, now, k, c, m);
    var raf = queueAnimationFrame(function (now) {
      return setState({
        now: now,
        spring: spring
      });
    });
    return function () {
      return unqueueAnimationFrame(raf);
    };
  }, [target, k, c, m, roundTo(x, decimals + 1), roundTo(v, decimals + 1)]);
  return roundTo(x, decimals);
}

function newSpring(target, x0, v0, start, k, c, m) {
  var d = target - x0;
  var spring = getSpring({
    k: k,
    c: c,
    m: m,
    x0: d,
    v0: v0
  });
  return function (now) {
    var t = (now - start) / 1000;

    var _spring = spring(t),
        _spring2 = _slicedToArray(_spring, 2),
        x = _spring2[0],
        v = _spring2[1];

    return [target - x, v];
  };
}

function getSpring(_ref3) {
  var k = _ref3.k,
      c = _ref3.c,
      m = _ref3.m,
      x0 = _ref3.x0,
      v0 = _ref3.v0;
  var radicand = c * c - 4 * k * m;

  if (radicand > 0) {
    var rp = (-c + sqrt(radicand)) / (2 * m);
    var rn = (-c - sqrt(radicand)) / (2 * m);
    var a = (x0 * rp - v0) / (rp - rn);
    var b = (v0 - x0 * rn) / (rp - rn);
    return function (t) {
      return [a * exp(rn * t) + b * exp(rp * t), a * rn * exp(rn * t) + b * rp * exp(rp * t)];
    };
  } else if (radicand < 0) {
    var r = -c / (2 * m);
    var s = sqrt(-radicand) / (2 * m);
    var _a = x0;

    var _b = (v0 - r * x0) / s;

    return function (t) {
      return [exp(r * t) * (_a * cos(s * t) + _b * sin(s * t)), exp(r * t) * ((_b * s + _a * r) * cos(s * t) - (_a * s - _b * r) * sin(s * t))];
    };
  } else {
    var _r = -c / (2 * m);

    var _a2 = x0;

    var _b2 = v0 - _r * x0;

    return function (t) {
      return [(_a2 + _b2 * t) * exp(_r * t), (_b2 + _a2 * _r + _b2 * _r * t) * exp(_r * t)];
    };
  }
}

function roundTo(x, decimals) {
  var p = pow(10, decimals);
  return round(x * p) / p;
}

var nextFrameQueue = [];
var nextFrameId = null;

function queueAnimationFrame(fn) {
  var length = nextFrameQueue.push(fn);

  if (length === 1) {
    nextFrameId = requestAnimationFrame(runQueue);
  }

  return [nextFrameId, length - 1];
}

function unqueueAnimationFrame(_ref4) {
  var _ref5 = _slicedToArray(_ref4, 2),
      frameId = _ref5[0],
      index = _ref5[1];

  if (frameId === nextFrameId) {
    delete nextFrameQueue[index];
  }
}

function runQueue() {
  var now = performance.now();
  var queue = nextFrameQueue;
  nextFrameQueue = [];
  unstable_batchedUpdates(function () {
    return queue.forEach(function (task) {
      return task && task(now);
    });
  });
}